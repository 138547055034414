import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import swal from "sweetalert";
import logo from "../../assets/images/logo/logo2.png";
import imagineLogo from "../../assets/images/logo/logo2.png";
import CustomButton from "../../components/common/CustomButton";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import {
  checkForSpecialCharacters,
  checkUserRole,
  cryptoSecretKey,
  loginUrl,
  reactServer,
  URL,
} from "../../utils/Constants";
import history from "../../utils/History";
import CryptoJS from "crypto-js";
import AsyncSelect from "react-select/async";
import { displayErrorMessage, displaySuccessAlert } from "../../utils/Utils";
import ModalWindow from "../UI/ModalWindow";

class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    passwordType: "password",
    showSpinner: false,
    schoolList: [],
    username: "",
    selectedSchool: null,
    logoPreview: "",
    modal: false,
    userType: [],
    forgotUsername: "",
    forgotUserType: "",
    viaAdmin: false,
    viaEmail: false,
    activeTab: "1",
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "school") {
      this.getSchoolsByName(value);
    }
  };

  getUserRoles = () => {
    axiosPost(URL.getUserRoleNoAuth, {}, (response) => {
      this.setState({ userType: response.data.data });
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    let params = {
      username: this.state.username,
      password: this.state.password,
    };

    if (this.state.username === "" && this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input both username and password",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else if (this.state.username === "") {
      swal({
        title: "Error",
        text: "Please input your username",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else if (this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input your password",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          loginUrl,
          params,
          (response) => {
            if (response?.status === 200) {
              this.loginResponseSetter(response);
            }
          },
          (error) => {
            let errorResponse = error.response ? error.response.data : error;
            let errorTitle = errorResponse.error
              ? errorResponse.error === "Internal Server Error"
                ? "Unable to Login"
                : errorResponse.error
              : "Unable to Login";
            swal({
              title: errorTitle,
              text: errorResponse.message
                ? errorResponse.message
                : "Could not connect to the server",
            });

            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        if (data.length > 0) {
          // setWebTitle(data[0].title);
          // setDisclaimer(data[0].disclaimer);
          // setFaviconPreview(data[0].faviconPath);
          this.setState({
            logoPreview: data[0].logoPath,
          });
        }
      }
    });
  };

  componentDidMount() {
    // this.getWebsiteConfig();
    this.getUserRoles();
  }

  handleInstitutionLogin = (e) => {
    e.preventDefault();
    let params = {
      username: this.state.username.trim(),
      password: this.state.password.trim(),
      schoolId: this.state.selectedSchool?.id,
      isSchoolAllowed: false,
    };

    if (
      this.state.username === "" &&
      this.state.password === "" &&
      this.state.selectedSchool === null
    ) {
      swal({
        title: "Error",
        text: "Please enter all the fields.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else if (this.state.username === "") {
      swal({
        title: "Error",
        text: "Please input your username",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else if (this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input your password",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else if (this.state.selectedSchool === null) {
      swal({
        title: "Error",
        text: "Please enter school code",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          loginUrl,
          params,
          (response) => {
            if (response.status === 200) {
              this.loginResponseSetter(response);
            }
          },
          (error) => {
            let errorResponse = error.response ? error.response.data : error;
            let errorTitle = errorResponse.error
              ? errorResponse.error === "Internal Server Error"
                ? "Unable to Login"
                : errorResponse.error
              : "Unable to Login";
            swal({
              title: errorTitle,
              text: errorResponse.message
                ? errorResponse.message
                : "Could not connect to the server",
            });

            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  // getSchoolsByName = (value) => {
  //   axiosPost(
  //     URL.getSchoolsByName,
  //     { name: value },
  //     (response) => {
  //       if (response.status === 200) {
  //         this.setState({ schoolList: response.data.data });
  //       }
  //     },
  //     (err) => {},
  //     true
  //   );
  // };

  loginResponseSetter = (response) => {
    let userRole = response.data.permissionWrapper;
    localStorage.setItem("roleName", userRole.roleName);
    localStorage.setItem("yearLevel", "Class");
    localStorage.setItem("classroom", "Section");
    localStorage.setItem("educationLevelDivision", "Term");

    localStorage.setItem(
      "permissions",
      CryptoJS.AES.encrypt(JSON.stringify(userRole.modules), cryptoSecretKey)
    );

    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("is2FAEnabled", response.data.is2FaEnabled);

    if (response.data.is2FaEnabled) {
      localStorage.setItem("otpVerified", false);
    }

    if (response.data.userEmail !== "") {
      localStorage.setItem("canUse2FA", true);
    } else {
      localStorage.setItem("canUse2FA", false);
    }

    // if (!response.data.is2FaEnabled) {
    localStorage.setItem("schoolSetup", true);
    // localStorage.setItem("schoolSetup", response.data.schoolSetup);
    localStorage.setItem(
      "educationCommunityGroupName",
      response.data.educationCommunityGroupName
    );
    localStorage.setItem("academicYears", true);
    localStorage.setItem("refreshToken", response.data.refresh_token);

    localStorage.setItem("isFirstLogin", response.data.isFirstLogin);

    localStorage.setItem(
      "widgets",
      CryptoJS.AES.encrypt(
        JSON.stringify(response.data.academicPermissionDto.widgets),
        "saurav_imagine_sys"
      )
    );
    response.data.substituteTeacherRolePermissions =
      response.data.substituteTeacherRolePermissions || null;
    localStorage.setItem(
      "substituteTeacherRolePermissions",
      CryptoJS.AES.encrypt(
        JSON.stringify(response.data.substituteTeacherRolePermissions),
        "saurav_imagine_sys"
      )
    );

    localStorage.setItem(
      "academicYearId",
      response.data.academicPermissionDto.academicYearId
    );
    localStorage.setItem(
      "subRoles",
      CryptoJS.AES.encrypt(
        JSON.stringify(response.data.subRoles),
        "saurav_imagine_sys"
      )
    );
    if (response.data.associatedUsers) {
      localStorage.setItem(
        "associatedUsers",
        CryptoJS.AES.encrypt(
          JSON.stringify(response.data.associatedUsers),
          "saurav_imagine_sys"
        )
      );
    }

    localStorage.setItem(
      "role",
      CryptoJS.AES.encrypt(
        response.data.userRoleCode,
        "saurav_imagine_sys"
      ).toString()
    );
    localStorage.setItem("peopleId", response.data.peopleId);
    localStorage.setItem("profilePeopleId", response.data.peopleId);
    localStorage.setItem("familyId", response.data.familyId);
    localStorage.setItem("mainUserId", response.data.userId);
    localStorage.setItem("schoolId", response.data.schoolId);

    //   localStorage.setItem(
    //     "isCoordinator",
    //     response.data.isCoordinator
    //   );
    //   localStorage.setItem(
    //     "isHomeRoomTeacher",
    //     response.data.isHomeRoomTeacher
    //   );
    if (this.state.rememberMe) {
      localStorage.setItem(
        "username",
        CryptoJS.AES.encrypt(
          this.state.username,
          "saurav_imagine_sys"
        ).toString()
      );
      localStorage.setItem(
        "password",
        CryptoJS.AES.encrypt(
          this.state.password,
          "saurav_imagine_sys"
        ).toString()
      );
    }
    //this.getRecruitmentIdPromise(response.data.peopleId);
    this.setState({
      showSpinner: false,
      OTPToggle: response.data.is2FaEnabled,
    });
    this.redirectTer(response);

    // if (response.data.userId == 1 || checkUserRole()) {
    //   history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
    // } else if (response.data.userRoleCode === "RL_STC") {
    //   history.push(
    //     `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
    //   );
    // } else {
    //   response.data.schoolSetup
    //     ? response.data.academicYears
    //       ? history.push(`${process.env.PUBLIC_URL}/profile/`)
    //       : history.push(`${process.env.PUBLIC_URL}/academicYears`)
    //     : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
    // }
    // }
    // else {
    //   this.setState({
    //     responseLoginData: response.data,
    //     OTPToggle: true,
    //   });
    // }
  };

  redirectTer = (response) => {
    if (response.data.is2FaEnabled) {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/otp`,
        state: response?.data,
      });
    }
    // else if (
    //   response.data.userId == 1 ||
    //   checkUserRole() ||
    //   response.data.userRoleCode === "RL_NATION" ||
    //   response.data.userRoleCode === "RL_LOCAL" ||
    //   response.data.userRoleCode === "RL_DISTRICT"
    // ) {
    //   // history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
    //   swal("Error", "User does not exist for the system");
    // }
    else if (
      response.data.schoolId === "" ||
      response.data.schoolId === null ||
      response.data.schoolId === undefined
    ) {
      if (
        response.data.userRoleCode === "RL_STD" ||
        response.data.userRoleCode === "RL_OTH"
      ) {
        history.push(`${process.env.PUBLIC_URL}/student`);
      } else if (response.data.userRoleCode === "RL_STF") {
        history.push(`${process.env.PUBLIC_URL}/teacher`);
      } else if (response.data.userRoleCode === "RL_STC") {
        history.push(
          `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
        );
      }
    } else if (response.data.userRoleCode === "RL_STC") {
      history.push(
        `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
      );
    } else if (
      response.data.schoolId &&
      (response.data.userRoleCode === "RL_STD" ||
        response.data.userRoleCode === "RL_OTH")
    ) {
      response.data.schoolSetup
        ? response.data.academicYears
          ? history.push(`${process.env.PUBLIC_URL}/profile/`)
          : history.push(`${process.env.PUBLIC_URL}/academicYears`)
        : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
    } else if (
      response.data.schoolId &&
      response.data.userRoleCode === "RL_STC"
    ) {
      response.data.schoolSetup
        ? response.data.academicYears
          ? history.push(`${process.env.PUBLIC_URL}/profile/`)
          : history.push(`${process.env.PUBLIC_URL}/academicYears`)
        : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
    } else {
      response.data.schoolSetup
        ? response.data.academicYears
          ? history.push(`${process.env.PUBLIC_URL}/profile/`)
          : history.push(`${process.env.PUBLIC_URL}/academicYears`)
        : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
    }
  };

  asyncSchoolChange = (val) => {
    if (checkForSpecialCharacters(val)) {
      return new Promise((resolve, reject) => {
        axiosPost(
          URL.getSchoolByCode,
          { name: val, isAllowed: false },
          (response) => {
            if (response.status === 200) {
              const schools = response.data.data;
              schools.forEach((s) => {
                s.label = s.name;
                s.value = s.code;
              });
              resolve(schools);
            } else {
              reject();
            }
          },
          (err) => {
            reject(err);
          },
          true
        );
      });
    }
  };

  handleSchoolChange = (selected) => {
    if (selected && selected.hasInstance) {
      this.setState({ selectedSchool: selected });
    } else if (selected == null) {
      this.setState({ selectedSchool: selected });
    } else {
      swal(
        "Warning",
        "System is not available for this school. Please contact your school."
      );
      return;
    }
  };

  handleDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleForgetPassword = (e) => {
    e.preventDefault();

    if (
      this.state.forgotUserType !== "" &&
      this.state.forgotUsername !== "" &&
      (this.state.viaAdmin || this.state.viaEmail)
    ) {
      let params = {
        username: this.state.forgotUsername,
        userRoleCode: this.state.forgotUserType,
        link: this.state?.viaAdmin
          ? ""
          : `${reactServer}/resetPassword/:userId`,
      };

      //url must be different for different password reset options for user

      let url = "";
      if (this.state.viaAdmin) {
        url = URL.forgetPassword;
      } else {
        url = URL.resetViaEmail;
      }

      this.setState(
        {
          showSpinner: true,
        },
        () => {
          axiosPost(
            url,
            params,
            (response) => {
              this.setState({ showSpinner: false });
              if (response.status === 200) {
                swal(
                  "Success",
                  "Password reset request has been successfully sent."
                );
                // setTimeout(() => {
                //   history.push(`${process.env.PUBLIC_URL}/`);
                // }, 5000);
                // displaySuccessAlert(response);
                this.setState({
                  modal: !this.state.modal,
                  forgotUsername: "",
                  forgotUserType: "",
                });
              }
            },
            (err) => {
              this.setState({ showSpinner: false });
              displayErrorMessage(err);
            }
          );
        }
      );
    } else {
      swal("Error", "Please input username, user type and reset option");
    }
  };
  forgotPasswordModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleCheckboxA = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked, viaAdmin: false });
  };

  handleCheckboxB = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked, viaEmail: false });
  };

  forgotModalBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-5">
            <strong>Username </strong>
          </div>
          <div className="col-md-7">
            <input
              name="forgotUsername"
              type="text"
              className="form-control"
              value={this.state.forgotUsername}
              placeholder="Type your Username"
              onChange={this.handleDataChange}
            ></input>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-md-5">
            <strong>User Type</strong>
          </div>
          <div className="col-md-7">
            <select
              className="form-control"
              name="forgotUserType"
              value={this.state.forgotUserType}
              onChange={this.handleDataChange}
            >
              <option value="" disabled>
                Choose user type
              </option>
              {this.state.userType.map((type, idx) => {
                return (
                  <option key={idx} value={type.code}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-md-5">
            <strong>Reset Password Option</strong>
          </div>
          <div className="col-md-7">
            <div>
              <label className="">Via Email</label>
              <input
                style={{ marginLeft: "10px" }}
                name="viaEmail"
                type="checkbox"
                checked={this.state?.viaEmail}
                onChange={(e) => this.handleCheckboxA(e)}
                className="tt-checkbox"
              // disabled={this.state?.viaAdmin}
              />
            </div>
            <div>
              <label className="">Via Admin</label>
              <input
                style={{ marginLeft: "10px" }}
                name="viaAdmin"
                type="checkbox"
                checked={this.state?.viaAdmin}
                onChange={(e) => this.handleCheckboxB(e)}
                className="tt-checkbox"
              // disabled={this.state?.viaEmail}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  forgotModalFooter = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col text-right">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize mr-3"
              onClick={this.handleForgetPassword}
            >
              Submit
            </button>
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.forgotPasswordModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Logging in..."></CustomSpinner>
        ) : null}
        <div className="loginDiv">
          <div className="row">
            <div className="col-md-5 loginBg"></div>
            <div className="col-md-7 loginBoxDiv">
              <div className="loginBoxHolder">
                {/* <div style={{ position: "absolute", right: 0, top: "5%" }}>
                  <Link to="/">
                    <strong style={{ fontSize: "1.2rem" }}>Home</strong>
                  </Link>
                </div> */}
                {/* {this.state.logoPreview ? ( */}
                <img
                  src={imagineLogo}
                  style={{ height: "80px", width: "auto" }}
                  onError={(e) => (e.target.src = imagineLogo)}
                  alt="school-logo"
                />
                {/* // ) : (
                //   <img src={imagineLogo}></img>
                // )} */}

                <div className="login-toggler mt-2">
                  {/* <h2 className="loginTitle">Sign In</h2> */}

                  {/* <div className="tabs">
                    <div className="tab-2">
                      <label
                        htmlFor="tab2-1"
                        className={
                          "indTab " +
                          (this.state.activeTab === "1" ? "actTab" : "")
                        }
                      >
                        Individual
                      </label>
                      <input
                        id="tab2-1"
                        name="tabs-two"
                        type="radio"
                        checked={this.state.activeTab === "1"}
                        onClick={() => this.setState({ activeTab: "1" })}
                      />
                      {this.state.activeTab === "1" && (
                        <div>
                          <form onSubmit={e => this.handlelogin(e)}>
                            <div>
                              <div className="position-relative">
                                <span
                                  className="material-icons-round loginIcon"
                                  aria-hidden="true"
                                >
                                  account_circle
                                </span>
                                <input
                                  type="text"
                                  name="username"
                                  className="customTextInput"
                                  value={this.state.username}
                                  onChange={this.handleChange}
                                  placeholder="Username"
                                ></input>
                              </div>
                              <div className="position-relative">
                                <span className="material-icons-round loginIconPsw">
                                  key
                                </span>
                                <input
                                  type={this.state.passwordType}
                                  name="password"
                                  className="customTextInput mt-2"
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  placeholder="Password"
                                ></input>
                              </div>

                              <div className="d-flex justify-content-between mt-2 position-relative">
                                <span className="forgotPsw">
                                  Don't have an account?{" "}
                                  <span className="forgotPsw-hover">
                                    <Link
                                      to="/signup"
                                      // onClick={this.toggleDropdown}
                                    >
                                      <u>Sign up</u>
                                    </Link>
                                  </span>{" "}
                                </span>
                                <Link
                                  to="/resetPassword"
                                  className="forgotPsw-hover"
                                >
                                  {" "}
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className="text-center mt-3">
                                <CustomButton
                                  type="submit"
                                  text="Login"
                                  customStyle="loginBtn"
                                  onClick={this.handleLogin}
                                ></CustomButton>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                    <div className="tab-2">
                      <label
                        htmlFor="tab2-2"
                        className={
                          "insTab " +
                          (this.state.activeTab === "2" ? "actTab" : "")
                        }
                      >
                        Institutional
                      </label>
                      <input
                        id="tab2-2"
                        name="tabs-two"
                        type="radio"
                        checked={this.state.activeTab === "2"}
                        onClick={() => this.setState({ activeTab: "2" })}
                      />
                      {this.state.activeTab === "2" && (
                        <div>
                          <form onSubmit={e => this.handleInstitutionLogin(e)}>
                            <div>
                              <div className="position-relative">
                                <span
                                className="material-icons-round loginIcon mt-2"
                                aria-hidden="true"
                              >
                                school
                              </span>
                                <span
                                  className="material-icons-round loginReactIcon mt-2"
                                  aria-hidden="true"
                                >
                                  school
                                </span>
                                <AsyncSelect
                                  cacheOptions
                                  loadOptions={this.asyncSchoolChange}
                                  value={this.state.selectedSchool}
                                  onChange={sel => this.handleSchoolChange(sel)}
                                  // className="inputColor"
                                  isClearable={true}
                                  placeholder="Type your IEMIS Code"
                                  classNamePrefix="loginReactSelect"
                                />
                                <input
                                type="text"
                                name="school"
                                list="schoolList"
                                className="customTextInput mt-2"
                                placeholder="Type your institution name"
                                onChange={this.handleChange}
                                value={this.state.school}
                              />
                              <datalist
                                id="schoolList"
                                className="customTextInput"
                              >
                                {this.state.schoolList.map((sc) => (
                                  <>
                                    <option value={sc.code}>{sc.name}</option>
                                  </>
                                ))}
                              </datalist>
                              </div>
                              <div className="position-relative">
                                <span
                                  className="material-icons-round loginIcon mt-2"
                                  aria-hidden="true"
                                >
                                  account_circle
                                </span>

                                <input
                                  type="text"
                                  name="username"
                                  className="customTextInput mt-2"
                                  value={this.state.username}
                                  onChange={this.handleChange}
                                  placeholder="Username"
                                ></input>
                              </div>
                              <div className="position-relative">
                                <span className="material-icons-round loginIconPsw">
                                  key
                                </span>
                                <input
                                  type={this.state.passwordType}
                                  name="password"
                                  className="customTextInput mt-2"
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  placeholder="Password"
                                ></input>
                              </div>

                              <div className="d-flex justify-content-around mt-2">
                                <span className="forgotPsw">
                                  Don't have an account?{" "}
                                  <span className="forgotPsw-hover ">
                                    <Link
                                      to="/signup"
                                      // onClick={this.toggleDropdown}
                                    >
                                      <u>Sign up</u>
                                    </Link>
                                  </span>{" "}
                                  here.
                                </span>
                                <span className="forgotPsw-hover">
                                  {" "}
                                  Forgot Password ?
                                </span>
                              </div>
                              <div className="text-center mt-3">
                                <CustomButton
                                  text="Login"
                                  type="submit"
                                  customStyle="loginBtn"
                                  onClick={
                                    this.state.activeTab === "1"
                                      ? this.handleLogin
                                      : this.handleInstitutionLogin
                                  }
                                ></CustomButton>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div> */}

                  <div>
                    <form>
                      <div>
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            school
                          </span>
                          <span
                            className="material-icons-round loginReactIcon mt-2"
                            aria-hidden="true"
                          >
                            school
                          </span>
                          <AsyncSelect
                            cacheOptions
                            loadOptions={this.asyncSchoolChange}
                            value={this.state.selectedSchool}
                            onChange={(sel) => this.handleSchoolChange(sel)}
                            // className="inputColor"
                            isClearable={true}
                            placeholder="Type your School Code"
                            classNamePrefix="loginReactSelect"
                          />
                          {/* <input
                            type="text"
                            name="school"
                            list="schoolList"
                            className="customTextInput mt-2"
                            placeholder="Type your institution name"
                            onChange={this.handleChange}
                            value={this.state.school}
                          />
                          <datalist id="schoolList" className="customTextInput">
                            {this.state.schoolList.map(sc => (
                              <>
                                <option value={sc.code}>{sc.name}</option>
                              </>
                            ))}
                          </datalist> */}
                        </div>
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            account_circle
                          </span>

                          <input
                            type="text"
                            name="username"
                            className="customTextInput mt-2"
                            value={this.state.username}
                            onChange={this.handleChange}
                            placeholder="Username"
                          ></input>
                        </div>
                        <div className="position-relative">
                          <span className="material-icons-round loginIconPsw">
                            key
                          </span>
                          <input
                            type={this.state.passwordType}
                            name="password"
                            className="customTextInput mt-2"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Password"
                          ></input>
                        </div>

                        <div className="d-flex justify-content-around mt-2">
                          {/* <span className="forgotPsw">
                            Don't have an account?{" "}
                            <span className="forgotPsw-hover ">
                              <Link
                                to={{
                                  pathname: "/signup",
                                  state: "private-school",
                                }}
                                // onClick={this.toggleDropdown}
                              >
                                <u>Sign up</u>
                              </Link>
                            </span>{" "}
                            here.
                          </span> */}
                          <p
                            className="tt-forgot-password forgotPsw-hover"
                            onClick={this.forgotPasswordModal}
                          >
                            Forgot password?
                          </p>
                        </div>
                        <div className="text-center mt-3">
                          <CustomButton
                            text="Login"
                            type="submit"
                            customStyle="loginBtn"
                            onClick={
                              this.state.activeTab == "1"
                                ? this.handleInstitutionLogin
                                : this.handleLogin
                            }
                          ></CustomButton>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="bottomText" id="click">
                    {/* <img src={logo} alt="mini logo" className="mb-2" /> */}
                    Augment Your Learning
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.modal}
          modalClass="forgotModal"
          modalHeader="Request New Password"
          modalBody={this.forgotModalBody()}
          modalFooter={this.forgotModalFooter()}
        ></ModalWindow>
      </div>
    );
  }
}

export default withRouter(LoginPage);
