import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import swal from "sweetalert";
import logo from "../../assets/images/logo/logo2.png";
import imagineLogo from "../../assets/images/logo/logo2.png";
import CustomButton from "../../components/common/CustomButton";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import {
  checkUserRole,
  cryptoSecretKey,
  loginUrl,
  URL
} from "../../utils/Constants";
import history from "../../utils/History";
import CryptoJS from "crypto-js";
import AsyncSelect from "react-select/async";
import OTPpage from "./OTPpage";

class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    passwordType: "password",
    showSpinner: false,
    schoolList: [],
    activeTab: "1",
    username: "",
    selectedSchool: null,
    OTPToggle: false,
    logoPreview: ""
  };

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "school") {
      this.getSchoolsByName(value);
    }
  };

  handleLogin = e => {
    e.preventDefault();
    let params = {
      username: this.state.username,
      password: this.state.password
    };

    if (this.state.username === "" && this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input both username and password",
        allowOutsideClick: false,
        closeOnClickOutside: false
      });
    } else if (this.state.username === "") {
      swal({
        title: "Error",
        text: "Please input your username",
        allowOutsideClick: false,
        closeOnClickOutside: false
      });
    } else if (this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input your password",
        allowOutsideClick: false,
        closeOnClickOutside: false
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          loginUrl,
          params,
          response => {
            if (response?.status === 200) {
              this.loginResponseSetter(response);
            }
          },
          error => {
            let errorResponse = error.response ? error.response.data : error;
            let errorTitle = errorResponse.error
              ? errorResponse.error === "Internal Server Error"
                ? "Unable to Login"
                : errorResponse.error
              : "Unable to Login";
            swal({
              title: errorTitle,
              text: errorResponse.message
                ? errorResponse.message
                : "Could not connect to the server"
            });

            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, response => {
      if (response.status === 200) {
        let data = response.data.data;
        if (data.length > 0) {
          // setWebTitle(data[0].title);
          // setDisclaimer(data[0].disclaimer);
          // setFaviconPreview(data[0].faviconPath);
          this.setState({
            logoPreview: data[0].logoPath
          });
        }
      }
    });
  };

  componentDidMount() {
    this.getWebsiteConfig();
  }

  handleInstitutionLogin = e => {
    e.preventDefault();
    let params = {
      username: this.state.username.trim(),
      password: this.state.password.trim(),
      schoolId: this.state.selectedSchool?.id
    };
    if (
      this.state.username === "" &&
      this.state.password === "" &&
      this.state.selectedSchool === null
    ) {
      swal({
        title: "Error",
        text: "Please enter all the fields.",
        allowOutsideClick: false,
        closeOnClickOutside: false
      });
    } else if (this.state.username === "") {
      swal({
        title: "Error",
        text: "Please input your username",
        allowOutsideClick: false,
        closeOnClickOutside: false
      });
    } else if (this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input your password",
        allowOutsideClick: false,
        closeOnClickOutside: false
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          loginUrl,
          params,
          response => {
            if (response.status === 200) {
              this.loginResponseSetter(response);
            }
          },
          error => {
            let errorResponse = error.response ? error.response.data : error;
            let errorTitle = errorResponse.error
              ? errorResponse.error === "Internal Server Error"
                ? "Unable to Login"
                : errorResponse.error
              : "Unable to Login";
            swal({
              title: errorTitle,
              text: errorResponse.message
                ? errorResponse.message
                : "Could not connect to the server"
            });

            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  // getSchoolsByName = (value) => {
  //   axiosPost(
  //     URL.getSchoolsByName,
  //     { name: value },
  //     (response) => {
  //       if (response.status === 200) {
  //         this.setState({ schoolList: response.data.data });
  //       }
  //     },
  //     (err) => {},
  //     true
  //   );
  // };

  loginResponseSetter = response => {
    let userRole = response.data.permissionWrapper;
    localStorage.setItem("roleName", userRole.roleName);
    localStorage.setItem(
      "permissions",
      CryptoJS.AES.encrypt(JSON.stringify(userRole.modules), cryptoSecretKey)
    );

    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("is2FAEnabled", response.data.is2FaEnabled);

    if (response.data.userEmail !== "") {
      localStorage.setItem("canUse2FA", true);
    } else {
      localStorage.setItem("canUse2FA", false);
    }

    // if (!response.data.is2FaEnabled) {
    localStorage.setItem("schoolSetup", response.data.schoolSetup);
    localStorage.setItem(
      "educationCommunityGroupName",
      response.data.educationCommunityGroupName
    );
    localStorage.setItem("academicYears", response.data.academicYears);
    localStorage.setItem("refreshToken", response.data.refresh_token);

    localStorage.setItem("isFirstLogin", response.data.isFirstLogin);

    localStorage.setItem(
      "widgets",
      CryptoJS.AES.encrypt(
        JSON.stringify(response.data.academicPermissionDto.widgets),
        "saurav_imagine_sys"
      )
    );
    response.data.substituteTeacherRolePermissions =
      response.data.substituteTeacherRolePermissions || null;
    localStorage.setItem(
      "substituteTeacherRolePermissions",
      CryptoJS.AES.encrypt(
        JSON.stringify(response.data.substituteTeacherRolePermissions),
        "saurav_imagine_sys"
      )
    );

    localStorage.setItem(
      "academicYearId",
      response.data.academicPermissionDto.academicYearId
    );
    localStorage.setItem(
      "subRoles",
      CryptoJS.AES.encrypt(
        JSON.stringify(response.data.subRoles),
        "saurav_imagine_sys"
      )
    );
    if (response.data.associatedUsers) {
      localStorage.setItem(
        "associatedUsers",
        CryptoJS.AES.encrypt(
          JSON.stringify(response.data.associatedUsers),
          "saurav_imagine_sys"
        )
      );
    }

    localStorage.setItem(
      "role",
      CryptoJS.AES.encrypt(
        response.data.userRoleCode,
        "saurav_imagine_sys"
      ).toString()
    );
    localStorage.setItem("peopleId", response.data.peopleId);
    localStorage.setItem("profilePeopleId", response.data.peopleId);
    localStorage.setItem("familyId", response.data.familyId);
    localStorage.setItem("mainUserId", response.data.userId);
    localStorage.setItem("schoolId", response.data.schoolId);

    //   localStorage.setItem(
    //     "isCoordinator",
    //     response.data.isCoordinator
    //   );
    //   localStorage.setItem(
    //     "isHomeRoomTeacher",
    //     response.data.isHomeRoomTeacher
    //   );
    if (this.state.rememberMe) {
      localStorage.setItem(
        "username",
        CryptoJS.AES.encrypt(
          this.state.username,
          "saurav_imagine_sys"
        ).toString()
      );
      localStorage.setItem(
        "password",
        CryptoJS.AES.encrypt(
          this.state.password,
          "saurav_imagine_sys"
        ).toString()
      );
    }
    //this.getRecruitmentIdPromise(response.data.peopleId);
    this.setState({
      showSpinner: false,
      OTPToggle: response.data.is2FaEnabled
    });
    this.redirectTer(response);
    // if (response.data.userId == 1 || checkUserRole()) {
    //   history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
    // } else if (response.data.userRoleCode === "RL_STC") {
    //   history.push(
    //     `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
    //   );
    // } else {
    //   response.data.schoolSetup
    //     ? response.data.academicYears
    //       ? history.push(`${process.env.PUBLIC_URL}/profile/`)
    //       : history.push(`${process.env.PUBLIC_URL}/academicYears`)
    //     : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
    // }
    // }
    // else {
    //   this.setState({
    //     responseLoginData: response.data,
    //     OTPToggle: true,
    //   });
    // }
  };

  redirectTer = response => {
    if (this.state.OTPToggle === true) {
      history.push(`${process.env.PUBLIC_URL}/otp`);
    } else {
      if (
        (this.state.OTPToggle === false && response.data.userId == 1) ||
        checkUserRole() ||
        response.data.userRoleCode === "RL_NATION" ||
        response.data.userRoleCode === "RL_LOCAL" ||
        response.data.userRoleCode === "RL_DISTRICT"
      ) {
        history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
      } else if (
        response.data.schoolId === "" ||
        response.data.schoolId === null ||
        response.data.schoolId === undefined
      ) {
        if (
          response.data.userRoleCode === "RL_STD" ||
          response.data.userRoleCode === "RL_OTH"
        ) {
          history.push(`${process.env.PUBLIC_URL}/student`);
        } else if (response.data.userRoleCode === "RL_STF") {
          history.push(`${process.env.PUBLIC_URL}/teacher`);
        } else if (response.data.userRoleCode === "RL_STC") {
          history.push(
            `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
          );
        }
      } else if (response.data.userRoleCode === "RL_STC") {
        history.push(
          `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
        );
      } else if (
        response.data.schoolId &&
        (response.data.userRoleCode === "RL_STD" ||
          response.data.userRoleCode === "RL_OTH")
      ) {
        response.data.schoolSetup
          ? response.data.academicYears
            ? history.push(`${process.env.PUBLIC_URL}/profile/`)
            : history.push(`${process.env.PUBLIC_URL}/academicYears`)
          : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
      } else if (
        response.data.schoolId &&
        response.data.userRoleCode === "RL_STC"
      ) {
        response.data.schoolSetup
          ? response.data.academicYears
            ? history.push(`${process.env.PUBLIC_URL}/profile/`)
            : history.push(`${process.env.PUBLIC_URL}/academicYears`)
          : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
      } else {
        response.data.schoolSetup
          ? response.data.academicYears
            ? history.push(`${process.env.PUBLIC_URL}/profile/`)
            : history.push(`${process.env.PUBLIC_URL}/academicYears`)
          : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
      }
    }
  };

  asyncSchoolChange = val => {
    return new Promise((resolve, reject) => {
      axiosPost(
        URL.getSchoolByCode,
        { name: val },
        response => {
          if (response.status === 200) {
            const schools = response.data.data;
            schools.forEach(s => {
              s.label = s.name;
              s.value = s.code;
            });
            resolve(schools);
          } else {
            reject();
          }
        },
        err => {
          reject(err);
        },
        true
      );
    });
  };

  handleSchoolChange = selected => {
    if (selected && selected.hasInstance) {
      this.setState({ selectedSchool: selected });
    } else if (selected == null) {
      this.setState({ selectedSchool: selected });
    } else {
      swal(
        "Warning",
        "System is not available for this school. Please contact your school."
      );
      return;
    }
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Logging in..."></CustomSpinner>
        ) : null}
        {this.state.OTPToggle ? <OTPpage /> : null}

        {/* // responseLoginData={this.state.responseLoginData}
        // username={this.state.username}
        // password={this.state.password}
        // rememberMe={this.state.rememberMe} */}
        <div className="loginDiv">
          <div className="row">
            <div className="col-md-5 loginBg"></div>
            <div className="col-md-7 loginBoxDiv">
              <div className="loginBoxHolder">
                {/* <div style={{ position: "absolute", right: 0, top: "5%" }}>
                  <Link to="/">
                    <strong style={{ fontSize: "1.2rem" }}>Home</strong>
                  </Link>
                </div> */}
                {/* {this.state.logoPreview ? ( */}
                <img
                  src={imagineLogo}
                  style={{ height: "80px", width: "auto" }}
                  onError={(e) => (e.target.src = imagineLogo)}
                  alt="school-logo"
                />
                {/* ) : (
                  <img src={imagineLogo}></img>
                )} */}

                <div className="login-toggler">
                  <div>
                    <h2 className="loginTitle">Sign In</h2>
                    <form>
                      <div>
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            account_circle
                          </span>

                          <input
                            type="text"
                            name="username"
                            className="customTextInput mt-2"
                            value={this.state.username}
                            onChange={this.handleChange}
                            placeholder="Username"
                          ></input>
                        </div>
                        <div className="position-relative">
                          <span className="material-icons-round loginIconPsw">
                            key
                          </span>
                          <input
                            type={this.state.passwordType}
                            name="password"
                            className="customTextInput mt-2"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Password"
                          ></input>
                        </div>

                        <div className="d-flex justify-content-around mt-2">
                          {/* <span className="forgotPsw">
                            Don't have an account?{" "}
                            <span className="forgotPsw-hover ">
                              <Link
                                to={{
                                  pathname: "/signup",
                                  state: "private-school",
                                }}
                                // onClick={this.toggleDropdown}
                              >
                                <u>Sign up</u>
                              </Link>
                            </span>{" "}
                            here.
                          </span> */}
                          <p
                            className="tt-forgot-password forgotPsw-hover"
                            onClick={this.forgotPasswordModal}
                          >
                            Forgot password?
                          </p>
                        </div>
                        <div className="text-center mt-3">
                          <CustomButton
                            text="Login"
                            type="submit"
                            customStyle="loginBtn"
                            onClick={
                              this.state.activeTab == "1"
                                ? this.handleInstitutionLogin
                                : this.handleLogin
                            }
                          ></CustomButton>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="bottomText" id="click">
                    {/* <img src={logo} alt="mini logo" className="mb-2" /> */}
                    Augment Your Learning
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);
